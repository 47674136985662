import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { MOBILE, SEC_TECHNOLOGIES } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import Divider from '../assets/images/divider.png'
import TechApp from '../assets/images/technologies/tech_app.png';
import TechAppMobile from '../assets/images/technologies/tech_app_mob.png';
import FirebaseIcon from '../assets/images/technologies/firebase_tech.png'
import AwsIcon from '../assets/images/technologies/aws_tech.png'
import PhpIcon from '../assets/images/technologies/php_tech.png'
import PostegresqlIcon from '../assets/images/technologies/postegresql_tech.png'
import ReactIcon from '../assets/images/technologies/react_tech.png'
import AngularIcon from '../assets/images/technologies/angular_tech.png'
import SpringIcon from '../assets/images/technologies/spring_tech.png'
import JavaIcon from '../assets/images/technologies/java_tech.png'

import SectionTitle from '../components/typography/SectionTitle';
import Paragraph from '../components/typography/Paragraph';
import Title from '../components/typography/Title';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';


export default function Technologies() {

  const sectionName = SEC_TECHNOLOGIES;

  const gridValue = useWindowSize();

  const [open, setOpen] = useState(null);

  const detail = [
    {
      title: "La miglior tecnologia: il miglior risultato",
      detail: "Non ci spaventa il cambiamento! Il mondo informatico di oggi è in continua espansione, proprio come noi. Blunìteam perciò non si affeziona alle tecnologie, ma offre alle sue risorse un apprendimento costante, basato sullo studio e sulla pratica di tutte le novità del campo."
    },
    {
      title: "Eleganza alla base di tutto",
      detail: "Il tuo progetto nelle nostre mani, sarà accudito sin dalla nascita. Tra i principali obiettivi, manteniamo la cura anche di ciò che non si vede: l'organizzazione del nostro codice ci permetterà infatti di riprendere il tuo progetto in qualsiasi momento, senza doverlo stravolgere completamente."
    },
    {
      title: "Collegamento con i sistemi di pagamento e autenticazione",
      detail: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
  ]

  const technologies = [
    FirebaseIcon,
    AwsIcon,
    AngularIcon,
    PostegresqlIcon,
    ReactIcon,
    PhpIcon,
    SpringIcon,
    JavaIcon
  ]

  function closeOthers(idx) {
    var panelList = [];
    for (let i = 0; i < detail.length; i++) {
      if (i !== idx) {
        var panel = document.getElementById(`${i}-panel`);
        panelList.push(panel);
      }
    }
    for (let i = 0; i < panelList.length; i++) {
      panelList[i].style.maxHeight = null;
    }
  }

  function handleCardClick(idx) {
    var panel = document.getElementById(`${idx}-panel`);
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
      setOpen(null);
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      closeOthers(idx);
      setOpen(idx);
    }
  }

  function renderDeskVersion() {
    return (
      <div id={sectionName} className="common-container tech-desk-container">
        <div id="triangle-bottomright"></div>
        <div style={{ position: 'relative', padding: '100px 0' }} className="container-dark">
          <img id="divider-topleft" src={Divider} alt='' />
          <Container className="">
            <SectionTitle className="rotated">TECNOLOGIE</SectionTitle>
            <div className="d-flex flex-column-reverse flex-xl-row align-items-center justify-content-center">
              <div className="col col-xl-6">
                <div className="d-flex flex-column justify-content-between">
                  {
                    detail.map((el, idx) => {
                      return (
                        <div key={idx} className="d-flex mb-5">
                          <Title className="text-light mr-4">{`${idx + 1}.`}</Title>
                          <div>
                            <Title className="text-light">{el.title}</Title>
                            <Paragraph className="dark-section">{el.detail}</Paragraph>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="col d-flex justify-content-center align-items-center">
                <div className="tech-animate-container">
                  <span className='d-none d-xl-block' id="circle-1"></span>
                  <span className='d-none d-xl-block' id="circle-2"></span>
                  <span className='d-none d-xl-block' id="circle-3"></span>
                  <span className='d-none d-xl-block' id="circle-4"></span>
                  <div className='image-container'>
                    <img src={TechAppMobile} alt='' />
                  </div>
                  {/* {
                    technologies.map((el, idx) => {
                      return (
                        <div key={idx} className="orbit" id={`orbit-${idx + 1}`}>
                          <div id={`bubble1-o${idx + 1}`}>
                            <img alt='' src={el} />
                          </div>
                        </div>
                      )
                    })
                  } */}
                </div>
              </div>
            </div>
          </Container>
          <img id="divider-bottomright" src={Divider} alt='' />
        </div>
        <div id="triangle-topleft"></div>
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="p-0 position-relative tech-section-mob">
        <div className="common-container overflow-hidden">
          <div id="triangle-bottomright"></div>
          <div
            style={{ position: 'relative', padding: '30px 0' }}
            className="container-dark">
            <img
              className="mob-v d-md-none d-lg-block"
              id="divider-topleft"
              src={Divider}
              alt='' />
            <Container className="">
              <SectionTitle className="mobile-v primary-light mb-4">TECNOLOGIE</SectionTitle>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="col d-flex justify-content-center align-items-center my-5">
                  <div className="tech-animate-container mob">
                    {/* <span id="circle-1"></span>
                    <span id="circle-2"></span>
                    <span id="circle-3"></span>
                    <span id="circle-4"></span> */}
                    <div className='image-container'>
                      <img src={TechAppMobile} alt='' />
                    </div>
                    {/* {
                      technologies.map((el, idx) => {
                        return (
                          <div key={idx} className="orbit" id={`orbit-${idx + 1}`}>
                            <div className="mob" id={`bubble1-o${idx + 1}`}>
                              <img alt='' src={el} />
                            </div>
                          </div>
                        )
                      })
                    } */}
                  </div>
                </div>
                <div className="col mt-5">
                  <div className="d-flex flex-column justify-conent-between">
                    {
                      detail.map((el, idx) => {
                        return (
                          <React.Fragment>
                            <div
                              onClick={() => handleCardClick(idx)}
                              key={idx}
                              id={`${idx}-accordion`}
                              className={`accordion tech-card`}>
                              <Paragraph className="card-title">
                                {el.title}
                              </Paragraph>
                              <div className="circle col d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon
                                  className="icon"
                                  icon={open === idx ? faChevronDown : faChevronRight} />
                              </div>
                            </div>
                            <div
                              id={`${idx}-panel`}
                              className={`panel`}>
                              <div className="pt-4 pb-2 px-2">{el.detail}</div>
                            </div>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </Container>
            <img
              className="mob-v d-md-none d-lg-block"
              id="divider-bottomright"
              src={Divider}
              alt='' />
          </div>
          <div id="triangle-topleft"></div>
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
