import React, { useState } from 'react'
import { MOBILE } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import ProcessStep1Desk2 from '../assets/images/process_step1_desk2.png'
import ProcessStep2Desk2 from '../assets/images/process_step2_desk2.png'
import ProcessStep3Desk2 from '../assets/images/process_step3_desk2.png'
import ProcessStep4Desk2 from '../assets/images/process_step4_desk2.png'
import ProcessStep5Desk2 from '../assets/images/process_step5_desk2.png'

import Title from '../components/typography/Title';
import Paragraph from '../components/typography/Paragraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';

export default function ProcessDetail() {

  const [active, setActive] = useState(0)

  const gridValue = useWindowSize();

  const sectionList = [
    {
      title: 'Illustraci la tua idea',
      shortTitle: 'Illustrazione idea',
      description: 'Il nostro primo incontro servirà a definire i parametri della tua idea. Faremo conoscenza, ci berremo un caffè e parleremo di ciò che vuoi realizzare con il nostro aiuto. Raccoglieremo tutto il materiale necessario al fine di curare ogni più piccolo dettaglio.',
      img: ProcessStep1Desk2
    },
    {
      title: 'Analisi del progetto',
      shortTitle: 'Analisi progetto',
      description: 'Tutte le informazioni ottenute durante il nostro primo incontro, verranno analizzate ed interpretate. Studieremo l’idea con le sue sfaccettature, valutandone la fattibilità e gli eventuali miglioramenti. E per lo sviluppo? Non ti preoccupare! Adatteremo la miglior tecnologia al tuo progetto, assicurandoci di sfruttarne al meglio tutte le capacità.',
      img: ProcessStep2Desk2
    },
    {
      title: 'Progettazione per intero del sistema informatico',
      shortTitle: 'Progettazione sistema',
      description: 'Il progetto verrà poi scomposto al fine di approfondirne le singole funzionalità. Ci occuperemo di definire l’architettura del sistema e progettarne le varie parti: suddividendo le risorse all’interno dell’infrastruttura, il prospetto della tua idea geniale inizierà a prendere vita. Forza e coraggio! Ci siamo quasi.',
      img: ProcessStep3Desk2
    },
    {
      title: 'Progettazione mockup (prototipo interattivo)',
      shortTitle: 'Progettazione mockup',
      description: 'Basandoci su un’attenta analisi del consumatore, inizieremo a gettare le basi per la creazione di un prototipo interattivo. La progettazione della User Experience è fondamentale: esamineremo il metodo migliore con cui l’utente potrà interfacciarsi all’applicazione. Superato questo passaggio, raffiguriamo la grafica dell’app in una User Interface. Tutti pronti? Ora si inizia a sviluppare!',
      img: ProcessStep4Desk2
    },
    {
      title: 'Sviluppo dell’applicazione',
      shortTitle: 'Sviluppo app',
      description: 'Il processo di sviluppo sarà suddiviso in fasi di lavoro ordinati per importanza. Al termine di ogni fase, ti mostreremo/faremo testare/faremo utilizzare quanto fatto  e discutermo assieme delle eventuali modifiche da includere nella fase successiva. In questo modo potrai seguire il progetto e partecipare alla sua evoluzione... intervenire nell’immediato ed evitare inutili perdite di tempo.',
      img: ProcessStep5Desk2
    },
  ]

  function renderDeskVersion() {

    const activeEl = sectionList[active];
    const listLength = sectionList.length;

    return (
      <Container className="process-container-det">
        <div className="d-flex align-items-center justify-content-between">
          <div className="col-5 d-flex flex-column">
            <Title>{activeEl.title}</Title>
            <Paragraph>{activeEl.description}</Paragraph>
            <div className="navigation d-flex align-items-start mt-5">
              <div className="container-arrow d-flex flex-column align-items-center">
                <div
                  onClick={() => active - 1 >= 0 && setActive(active - 1)}
                  className={`icon left ${active === 0 ? 'inactive' : 'active'}`}>
                  <FontAwesomeIcon icon={faLongArrowAltLeft} />
                </div>
                <div className="section-short-title text-center">
                  {sectionList[active - 1] ? sectionList[active - 1].shortTitle : ''}
                </div>
              </div>
              <Title className="mt-3 mx-5">{active + 1} / {listLength}</Title>
              <div className="container-arrow d-flex flex-column align-items-center">
                <div
                  onClick={() => active + 1 < listLength && setActive(active + 1)}
                  className={`icon right ${active === sectionList.length - 1 ? 'inactive' : 'active'}`}>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </div>
                <div className="section-short-title text-center">
                  {sectionList[active + 1] ? sectionList[active + 1].shortTitle : ''}
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 d-flex justify-content-center align-items-center">
            <img className="no-selectable" src={activeEl.img} alt={activeEl.title} />
          </div>
        </div>
      </Container>
    )
  }

  function renderMobileVersion() {
    const activeEl = sectionList[active];
    const listLength = sectionList.length;

    return (
      <Container className="my-5">
        <div className="d-flex flex-column align-items-center justify-content-between">
          <div style={{ height: '50vh' }} className="navigation mobile d-flex justify-content-end align-items-center">
            <div className="container-arrow d-flex flex-column align-self-end">
              <div
                onClick={() => active - 1 >= 0 && setActive(active - 1)}
                className={`icon left ${active === 0 ? 'inactive' : 'active'}`}>
                <FontAwesomeIcon icon={faLongArrowAltLeft} />
              </div>
            </div>
            <img className="no-selectable" src={activeEl.img} alt={activeEl.title} style={{ height: '100%', maxWidth: '55%' }} />
            <div className="container-arrow d-flex flex-column align-self-end text-right">
              <div
                onClick={() => active + 1 < listLength && setActive(active + 1)}
                className={`icon right ${active === sectionList.length - 1 ? 'inactive' : 'active'}`}>
                <FontAwesomeIcon icon={faLongArrowAltRight} />
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-5">
            <Title>{activeEl.title}</Title>
            <Paragraph>{activeEl.description}</Paragraph>
            <div className="d-flex mt-3 align-self-center">
              {
                sectionList.map((el, i) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={`#${i}-section`}
                      key={i}
                      className={`circle ${i === active ? 'active' : 'inactive'}`}></a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
