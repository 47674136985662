import React from 'react'

export default function Button({ children, buttonClicked, className }) {
  return (
    <button
      onClick={ () => buttonClicked() }
      className={`common-button ${className}`}>
      {children}
    </button>
  )
}
