import './App.scss';
import '../src/assets/scss/style.scss'
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import PortfolioPage from './pages/PortfolioPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayout />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
      </Routes>
    </Router >
  );
}

export default App;
