import React, { useState } from 'react'
import { Container } from 'react-bootstrap';
import { useWindowSize } from '../helpers/util';

import { ReactComponent as MenuIcon } from '../assets/icon/menu-icon.svg'
import MenuHeader from '../assets/images/menu_mob.png'
import { MOBILE, SEC_CONTACTS, SEC_METHODS, SEC_PORTFOLIO, SEC_PROCESS, SEC_SERVICES, SEC_TECHNOLOGIES } from '../common/constant';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Menu() {

  const gridValue = useWindowSize();

  const [isScrolled] = useState(false);

  const menuVoices = [
    { item: 'Servizi', ref: SEC_SERVICES },
    { item: 'Tecnologie', ref: SEC_TECHNOLOGIES },
    { item: 'Processo', ref: SEC_PROCESS },
    { item: 'Metodologia', ref: SEC_METHODS },
    { item: 'Portfolio', ref: SEC_PORTFOLIO },
    { item: 'Contatti', ref: SEC_CONTACTS },
  ]

  function handleOpenMenu() {
    const menu = document.querySelector('.menu-hover');
    menu.classList.remove('not-visible');
    menu.classList.add('visible');
  }

  function handleCloseMenu() {
    const menu = document.querySelector('.menu-hover');
    menu.classList.remove('visible');
    menu.classList.add('not-visible');
  }

  document.addEventListener('scroll', function (e) {
    const deskNav = document.querySelector('.desk-menu-container');
    const mobNav = document.querySelector('.mob-menu');

    if (deskNav != null) {
      if (window.pageYOffset > deskNav.clientHeight) {
        this.isScrolled = true;
        deskNav.classList.add('shadow');
      } else {
        this.isScrolled = false;
        deskNav.classList.remove('shadow');
      }
    }
    if (mobNav != null) {
      if (window.pageYOffset > mobNav.clientHeight) {
        this.isScrolled = true;
        mobNav.classList.add('shadow');
      } else {
        this.isScrolled = false;
        mobNav.classList.remove('shadow');
      }
    }
  });

  function renderDeskVersion() {
    return (
      <div  className={`desk-menu-container ${isScrolled ? 'shadow' : 'no-shadow'}`}>
        <Container className="desk-menu">
          <div className="d-flex align-items-center justify-content-between">
            <div className="title">
              BlunìTeam
            </div>
            <div className="d-flex menu">
              {
                menuVoices.map((el, idx) => {
                  return <div key={idx} className="voice-menu"><a href={`#${el.ref}`}>{el.item}</a></div>
                })
              }
            </div>
          </div>
        </Container>
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <div className="mob-menu">
        <div id="menuToggle" className="d-flex align-items-center justify-content-between">
          <div className="title">
            BlunìTeam
          </div>
          <div onClick={() => handleOpenMenu()}>
            <MenuIcon fill={'#0a2540'} className="icon" />
          </div>
        </div>
        <div className={`menu-hover not-visible`}>
          <div className="d-flex flex-column jusitfy-content-between h-100">
            <div className="heading d-flex align-items-center justify-content-between">
              <div className="title">BlunìTeam</div>
              <FontAwesomeIcon onClick={() => handleCloseMenu()} className="close-icon" icon={faTimes} />
            </div>
            <div className="d-flex flex-column align-items-end menu mt-auto">
              {
                menuVoices.map((el, idx) => {
                  return <div key={idx} className="voice-menu"><a onClick={() => handleCloseMenu()} href={`#${el.ref}`}>{el.item}</a></div>
                })
              }
            </div>
          </div>
          <img src={MenuHeader} alt='' />
        </div>
      </div>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
