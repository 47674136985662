import '../App.scss';
import '../assets/scss/style.scss'
import React from 'react';

import Header from './Header';
import Services from './Services';
import Work from './Work';
import Technologies from './Technologies';
import Integration from './Integration';
import Process from './Process';
import ProcessDetail from './ProcessDetail';
import Portfolio from './Portfolio';
import Contacts from './Contacts';
import Footer from './Footer';
import Menu from './Menu';

function RootLayout() {
  return (
    <React.Fragment>
      <Menu />
      <Header />
      <Work />
      <Services />
      <Technologies />
      <Integration />
      <Process />
      <ProcessDetail />
      <Portfolio />
      <Contacts />
      <Footer />
    </React.Fragment>
  );
}

export default RootLayout;