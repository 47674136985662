import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Button from '../components/action/Button'

export default function PortfolioPage() {

  const navigate = useNavigate();

  const onButtonClicked = () => {
    navigate("/");
  }

  return (
    <>
      <div>PortfolioPage</div>
      <div className='d-flex flex-row justify-content-center align-items-center my-5'>
        <Button
          className="button d-flex align-items-center justify-content-between"
          buttonClicked={() => onButtonClicked()}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-3" />
          Torna alla home
        </Button>
      </div>
    </>
  )
}
