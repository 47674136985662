import React from 'react'
import { MOBILE, SEC_CONTACTS } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import Title from '../components/typography/Title';
import SectionTitle from '../components/typography/SectionTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Contacts() {

  const sectionName = SEC_CONTACTS;

  const gridValue = useWindowSize();
  const sourceMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2786.839158456032!2d9.840994115772562!3d45.69419972608746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47815c16adb03ae7%3A0x41db1a82133deeb4!2sTH3LAB!5e0!3m2!1sit!2sit!4v1623944454276!5m2!1sit!2sit"

  function renderDeskVersion() {
    return (
      <div id={sectionName} className="contact-container position-relative d-flex flex-fill">
        <div className="contact d-flex align-items-center justify-content-center flex-fill">
          <div className="contact-detail d-flex flex-column flex-fill">
            <Title className="flex-fill">Sezione contatti</Title>
            <div className="mt-4 flex-fill">
              <div className="sec-title">Come contattarci</div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faEnvelopeOpen} />
                </div>
                <div className="ml-3 data">
                  info@bluniteam.it
                </div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                </div>
                <div className="ml-3 data">
                  (+39) 351 905 5382
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="sec-title">Dove siamo</div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faMapMarker} />
                </div>
                <div className="ml-3 data">
                  TH3 LAB,<br />
                  <small>Piazza Salvo D'Acquisto, 33, 24069 Trescore Balneario BG</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-fill">
          <iframe
            src={sourceMap} title='s' allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="contact-container mob position-relative d-flex flex-column flex-fill p-0 m-0">
        <SectionTitle className="mobile-v pl-4 mt-5">Contatti</SectionTitle>
        <div className="contact mob d-flex align-items-center justify-content-center flex-fill">
          <div className="contact-detail">
            <Title>Sezione contatti</Title>
            <div className="mt-4">
              <div className="sec-title">Come contattarci</div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faEnvelopeOpen} />
                </div>
                <div className="ml-3 data">
                  info@bluniteam.it
                </div>
              </div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                </div>
                <div className="ml-3 data">
                  (+39) 351 905 5382
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="sec-title">Dove siamo</div>
              <div className="d-flex align-items-center mt-2">
                <div className="circle">
                  <FontAwesomeIcon className="icon" icon={faMapMarker} />
                </div>
                <div className="ml-3 data">
                  TH3 LAB,<br />
                  <small>Piazza Salvo D'Acquisto, 33, 24069 Trescore Balneario BG</small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="map d-flex flex-fill">
          <iframe
            src={sourceMap} title='s' allowfullscreen="" loading="lazy"></iframe>
        </div>
      </section>
    )
  }
  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
