import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { MOBILE } from '../common/constant';
import Paragraph from '../components/typography/Paragraph';
import Title from '../components/typography/Title';
import { useWindowSize } from '../helpers/util';

import IntegrationDesk2 from '../assets/images/integration_desk2.png'

export default function Integration() {

  const title = "Un sistema integrato con il mondo";
  const paragraph = "Le nostre app si integrano con i servizi che utilizziamo ogni giorno, come i sistemi di pagamento Apple e Google, bluetooth, NFC, Social Login ecc.. Garantendoti una migliore esperienza.";

  const gridValue = useWindowSize();

  function renderDeskVersion() {
    return (
      <div className="position-relative">
        <Container style={{ height: '80vh' }}>
          <Row className="h-100 align-items-center">
            <Col md={4}>
              <Title>{title}</Title>
              <Paragraph>{paragraph}</Paragraph>
            </Col>
          </Row>
        </Container>
        <img className="integration-img-desk" src={IntegrationDesk2} alt='' />
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <section className="section-int-mob m-0 position-relative">
        <div className="integration-img-mob"  alt='' />
        <div className="title">{title}</div>
        <div className="common-paragraph">{paragraph}</div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
