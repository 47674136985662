import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeOpen, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { MOBILE } from '../common/constant';
import { useWindowSize } from '../helpers/util';

export default function Footer() {

  const gridValue = useWindowSize();

  function renderDeskVersion() {
    return (
      <>
        <div className="footer-container">
          <Container>
            <Row>
              <Col className="d-flex flex-column">
                <div className="website-name">BlunìTeam</div>
                <div className="website-caption">Lorem ipsum dolor sit amet</div>
                <div className="widget-title mt-5">Social utilizzati</div>
                <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
              </Col>
              <Col className="d-flex flex-column">
                <div className="widget-title mb-3">Pagine del menu</div>
                <div className="widget-data menu">Servizi</div>
                <div className="widget-data menu">Tecnologie</div>
                <div className="widget-data menu">Metodologia</div>
                <div className="widget-data menu">Portfolio</div>
                <div className="widget-data menu">Contatti</div>
              </Col>
              <Col className="d-flex flex-column">
                <div className="widget-title mb-3">Tutti i nostri contatti</div>
                <div className="d-flex flex-fill align-items-center">
                  <FontAwesomeIcon className="icon" icon={faMapMarker} />
                  <div className="widget-data ml-3">TH3 LAB<br />
                    Piazza Salvo D'Acquisto, 33, 24069<br />
                    Trescore Balneario BG</div>
                </div>
                <div className="d-flex flex-fill align-items-center">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                  <div className="widget-data ml-3">+39 351 905 5382</div>
                </div>
                <div className="d-flex flex-fill align-items-center">
                  <FontAwesomeIcon className="icon" icon={faEnvelopeOpen} />
                  <div className="widget-data ml-3">info@bluniteam.it</div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright-section p-3">
          <Container>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div>© {new Date().getFullYear()} BlunìTeam - Tutti i diritti riservati - P:IVA 89239562982</div>
              </div>
              <div>
                <span><u>Privacy Policy</u> e bla bla bla 🙄</span>
              </div>
            </div>
          </Container>
        </div>
      </>
    )
  }

  function renderMobileVersion() {
    return (
      <section className="p-0 m-0">
        <div className="footer-container px-4">
          <Container>
            <div className="d-flex flex-column">
              <div className="d-flex flex-column">
                <div className="website-name">BlunìTeam</div>
                <div className="website-caption">Lorem ipsum dolor sit amet</div>
                <div className="widget-title mt-3">Social utilizzati</div>
                <FontAwesomeIcon className="icon" icon={faLinkedinIn} />
              </div>
              <div className="d-flex flex-column mt-5">
                <div className="widget-title mb-3">Pagine del menu</div>
                <div className="widget-data menu">Servizi</div>
                <div className="widget-data menu">Tecnologie</div>
                <div className="widget-data menu">Metodologia</div>
                <div className="widget-data menu">Portfolio</div>
                <div className="widget-data menu">Contatti</div>
              </div>
              <div className="d-flex flex-column mt-5">
                <div className="widget-title mb-3">Tutti i nostri contatti</div>
                <div className="d-flex flex-fill align-items-center">
                  <FontAwesomeIcon className="icon" icon={faMapMarker} />
                  <div className="widget-data ml-3">TH3 LAB<br />
                    Piazza Salvo D'Acquisto, 33, 24069<br />
                    Trescore Balneario BG</div>
                </div>
                <div className="d-flex flex-fill align-items-center mt-2">
                  <FontAwesomeIcon className="icon" icon={faPhone} />
                  <div className="widget-data ml-3">+39 351 905 5382</div>
                </div>
                <div className="d-flex flex-fill align-items-center mt-2">
                  <FontAwesomeIcon className="icon" icon={faEnvelopeOpen} />
                  <div className="widget-data ml-3">info@bluniteam.it</div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="copyright-section p-3">
          <Container>
            <div className="d-flex flex-column align-items-center justify-content-between">
              <div className="text-center">
                <div className="text-center">© {new Date().getFullYear()} BlunìTeam - Tutti i diritti riservati - P:IVA 89239562982</div>
              </div>
              <div className="mt-2">
                <span><u>Privacy Policy</u> e bla bla bla 🙄</span>
              </div>
            </div>
          </Container>
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
