import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { MOBILE, SEC_METHODS } from '../common/constant';
import Paragraph from '../components/typography/Paragraph';
import Title from '../components/typography/Title';
import { useWindowSize } from '../helpers/util';

import { ReactComponent as WorkIcon } from '../assets/icon/work.svg'
import SectionTitle from '../components/typography/SectionTitle';

export default function Work() {

  const heading = "Come lavoriamo"
  const subHeading1 = "Siamo un Team che collaborano a stretto contatto con il cliente e sempre alla ricerca di nuove sfide: determinazione e passione sono tra i pilastri del nostro lavoro."
  const subHeading2 = "Saremo a tua completa disposizione per l'intera fase del progetto, sempre pronti ad invertire la rotta. Il vantaggio? Parlerai direttamente con chi progetta e sviluppa l'applicazione."

  const sectionName = SEC_METHODS;

  const gridValue = useWindowSize();

  function renderDeskVersion() {
    return (
      <Container id={sectionName} className="common-container mb-5">
        <Row className="align-items-center">
          <Col md={7}>
            <Title>{heading}</Title>
            <Paragraph>{subHeading1}</Paragraph>
            <Paragraph>{subHeading2}</Paragraph>
          </Col>
          <Col md={5}>
            <WorkIcon style={{ maxHeight: '300px' }} className="w-100 h-100" />
          </Col>
        </Row>
      </Container>
    )
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="work-section">
        <SectionTitle className="mobile-v">Come lavoriamo</SectionTitle>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <div>
            <Title>{heading}</Title>
            <Paragraph>{subHeading1}</Paragraph>
            <Paragraph>{subHeading2}</Paragraph>
          </div>
          <div className="mt-4 col-md-5 text-center">
            <WorkIcon style={{ maxWidth: '200px' }} className="w-100 h-100" />
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
