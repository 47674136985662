import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';

import { MOBILE, SEC_SERVICES } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { faApple, faAppStoreIos, faGooglePlay, faWindows } from '@fortawesome/free-brands-svg-icons';

import Title from '../components/typography/Title';
import Paragraph from '../components/typography/Paragraph';

import Service1Desk2 from '../assets/images/service1_desk2.png';
import Service2Desk2 from '../assets/images/service2_desk2.png';
import Service3Desk2 from '../assets/images/service3_desk2.png';
import SectionTitle from '../components/typography/SectionTitle';

export default function Services() {

  const sectionName = SEC_SERVICES;

  const [activeService, setActiveService] = useState(0);

  const servicesDetail = [
    {
      icon: [faDesktop, faMobileAlt],
      title: 'App web responsive',
      detail: 'Sviluppiamo applicazioni web responsive raggiungibili tramite un semplice Browser e compatibili con la maggior parte dei dispositivi. L’integrazione di tecnologie moderne, permetterà al progetto di assumere le sembianze di un’applicazione nativa: un mix d\'intuito e facilità d\'utilizzo.',
      img: Service1Desk2
    },
    {
      icon: [faAppStoreIos, faGooglePlay],
      title: 'App mobile iOS e Android',
      detail: 'La nostra preparazione ci permette di creare applicazioni native per IOS e Android, scaricabili dagli store ufficiali. Le varie prestazioni del tuo smartphone si uniranno alla nostra esperienza nello sviluppo, realizzando così prodotti unici, performanti e dalle prestazioni elevate.',
      img: Service2Desk2
    },
    {
      icon: [faApple, faWindows],
      title: 'App desktop MacOS e Windows',
      detail: 'La nostra squadra realizza applicazioni installabili direttamente sul computer desktop. Agevoliamo il tuo lavoro con il nostro, fornendoti strumenti d’utilizzo semplici, intuitivi e mai banali. Inizia subito: rendi ogni tua esigenza a portata di click.',
      img: Service3Desk2
    }
  ]

  const gridValue = useWindowSize();

  function renderDeskVersion() {
    return (
      <Container id={sectionName} className="common-container position-relative">
        <SectionTitle className="rotated">SERVIZI</SectionTitle>
        <div className="d-flex align-item-start justify-content-around">
          {
            servicesDetail.map((el, idx) => {
              return (
                <div
                  onClick={() => setActiveService(idx)}
                  key={idx}
                  className={`service-tab no-selectable ${idx === activeService ? 'active' : 'inactive'}`}>
                  <div className="icon">
                    {
                      el.icon.map((el, idxIcon) => {
                        return <FontAwesomeIcon icon={el} key={idxIcon} className="mx-1" />
                      })
                    }
                  </div>
                  <div style={{ width: '90%', lineHeight: 1 }} className="text-center mt-3">
                    {el.title}
                  </div>
                  <div
                    hidden={idx !== activeService}
                    className="flex-fill mt-2"
                    style={{
                      height: '4px',
                      maxHeight: '4px',
                      width: '100%',
                      background: '#0194ff',
                      borderRadius: 10
                    }}></div>
                </div>
              )
            })
          }
        </div>
        {
          servicesDetail[activeService] &&
          <Row className="d-flex flex-row align-items-center justify-content-between mt-3 service-detail-row">
            <Col md={5}>
              <Title>
                {servicesDetail[activeService].title}
              </Title>
              <Paragraph>
                {servicesDetail[activeService].detail}
              </Paragraph>
            </Col>
            <Col md={7} className="d-flex justify-content-center">
              <img className="w-100 no-selectable" src={servicesDetail[activeService].img} alt='' />
            </Col>
          </Row>
        }
      </Container>
    );
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="p-0 service-section">
        <div>
          <SectionTitle className="mobile-v pl-4">Servizi</SectionTitle>
          <div className="services-list d-flex mt-3 pl-4">
            {
              servicesDetail.map((el, idx) => {
                return (
                  <div className="flex-fill mr-3">
                    <div
                      onClick={() => setActiveService(idx)}
                      key={idx}
                      className={`service-tab no-selectable flex-fill ${idx === activeService ? 'active' : 'inactive'}`}>
                      <div className="icon">
                        {
                          el.icon.map((el, idxIcon) => {
                            return <FontAwesomeIcon icon={el} key={idxIcon} className="mx-1" />
                          })
                        }
                      </div>
                      <div style={{ width: '90%', lineHeight: 1, whiteSpace: 'normal' }} className="text-center flex-nowrap">
                        {el.title}
                      </div>
                      <div
                        hidden={idx !== activeService}
                        className="flex-fill mt-2"
                        style={{
                          height: '4px',
                          maxHeight: '4px',
                          width: '100%',
                          background: '#0194ff',
                          borderRadius: 10
                        }}></div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            servicesDetail[activeService] &&
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
              <Col md={5}>
                <Title>
                  {servicesDetail[activeService].title}
                </Title>
                <Paragraph>
                  {servicesDetail[activeService].detail}
                </Paragraph>
              </Col>
              <Col className="mt-5" md={7}>
                <img className="service-img-mob" src={servicesDetail[activeService].img} alt='' />
              </Col>
            </div>
          }
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}