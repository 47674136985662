import React from 'react'
import { MOBILE, SEC_PROCESS } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import ProcessDesk2 from '../assets/images/process_desk2.png'
import ProcessMob from '../assets/images/process_mob.png'


export default function Process() {

  const sectionName = SEC_PROCESS;

  const gridValue = useWindowSize();

  function renderDeskVersion() {
    return (
      <div className="process-container">
        <img src={ProcessDesk2} alt ='' />
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="process-section-mob position-relative p-0 m-0">
        <img style={{
          objectfit: 'cover',
          width: '100%',
        }} src={ProcessMob} alt='' />
      </section>
    )

  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
