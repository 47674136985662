export const DESKTOP_2 = "DESKTOP_2";
export const DESKTOP_1 = "DESKTOP_1";
export const TABLET = "TABLET";
export const MOBILE = "MOBILE";

export const SEC_SERVICES = "servizi"
export const SEC_TECHNOLOGIES = "tecnologie"
export const SEC_PROCESS = "processo"
export const SEC_METHODS = "come_lavoriamo"
export const SEC_PORTFOLIO = "portfolio"
export const SEC_CONTACTS = "contatti"