import { useLayoutEffect, useState } from "react";
import { DESKTOP_1, DESKTOP_2, MOBILE } from "../common/constant";


export function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  
  const width = size[0];
  
  if (width >= 1200) {
    return DESKTOP_2;
  }
  if (width >= 992) {
    return DESKTOP_1;
  }
  if (width >= 800 && width < 992) {
    return MOBILE;
  }
  if (width < 800) {
    return MOBILE;
  }

}