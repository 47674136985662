import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

// constant import 
import { MOBILE } from '../common/constant';

// components import
import HeadingTitle from '../components/typography/HeadingTitle';
import Paragraph from '../components/typography/Paragraph';
import Button from '../components/action/Button';

// other import
//import appImagesDesk2 from '../assets/images/header_desk2.png';
import HeaderImg1Desk2 from '../assets/images/header_img1_desk2.png';
import HeaderImg2Desk2 from '../assets/images/header_img2_desk2.png';
import HeaderImg3Desk2 from '../assets/images/header_img3_desk2.png';
import { useWindowSize } from '../helpers/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

export default function Header(props) {

  const heading = "Sviluppiamo App"
  const subHeading = "......"

  const gridValue = useWindowSize();

  function onButtonClicked() {
    window.scrollTo(0, document.getElementById('servizi').offsetTop)
  }

  function renderDeskVersion() {
    return (
      <Container className="heading-section">
        <Row style={{ height: '100%', alignItems: 'center', margin: '200px 0px' }}>
          <Col md={6}>
            <div className="d-flex flex-column align-items-start">
              <HeadingTitle>
                {heading}
              </HeadingTitle>
              <Paragraph>
                {subHeading}
              </Paragraph>
              <div className="my-4"></div>
              <Button buttonClicked={() => onButtonClicked()}>
                Contattaci
              </Button>
            </div>
          </Col>
          <Col style={{ height: '100%' }} md={6}>
            <div style={{ position: 'relative', height: '500px' }}>
              <img id="img1" className="no-selectable" alt='' src={HeaderImg1Desk2}>
              </img>
              <img id="img2" className="no-selectable" alt='' src={HeaderImg2Desk2}>
              </img>
              <img id="img3" className="no-selectable" alt='' src={HeaderImg3Desk2}>
              </img>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }


  function renderMobileVersion() {
    return (
      <section className="heading-section-mob pb-5" style={{ height: '100%', marginTop: '6.5rem' }}>
        <Button 
          className="button d-flex align-items-center justify-content-between" 
          buttonClicked={() => onButtonClicked()}>
          <FontAwesomeIcon icon={faLongArrowAltDown} className="mr-3" />
          SCOPRI
        </Button>
        <div className="d-flex flex-column align-items-center">
          <div className="title-container d-flex flex-column align-items-center pt-4">
            <HeadingTitle className={"text-center "}>
              {heading}
            </HeadingTitle>
            <Paragraph className={"text-center"}>
              {subHeading}
            </Paragraph>
          </div>
          <div className="mt-1 img-container">
            <img id="img1" className="no-selectable" alt='' src={HeaderImg1Desk2}>
            </img>
            <img id="img2" className="no-selectable" alt='' src={HeaderImg2Desk2}>
            </img>
            <img id="img3" className="no-selectable" alt='' src={HeaderImg3Desk2}>
            </img>
          </div>
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}

