import React from 'react'
import { MOBILE, SEC_PORTFOLIO } from '../common/constant';
import { useWindowSize } from '../helpers/util';

import BluniStudentDesk2 from '../assets/images/portfolio_blunìStudent_desk2.png'
import Title from '../components/typography/Title';
import Paragraph from '../components/typography/Paragraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import Divider from '../assets/images/divider.png'
import Pattern1 from '../assets/images/pattern/pattern1.png'
import SectionTitle from '../components/typography/SectionTitle';
import Button from '../components/action/Button';
import { useNavigate } from 'react-router-dom';

export default function Portfolio() {

  const sectionName = SEC_PORTFOLIO;

  const gridValue = useWindowSize();
  const navigate = useNavigate();

  const portfolioList = [
    {
      title: 'Illustraci la tua idea',
      description: 'Lorem ipsum dolor asdaaaaamet, consectetur adipiscing elit, sed do e',
      detail: [
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet',
        'Lorem ipsum dolor sit amet'
      ],
      img: BluniStudentDesk2,
      pattern: Pattern1
    },
    {
      title: 'Illustraci la tua idea',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e',
      img: BluniStudentDesk2,
      pattern: Pattern1
    },
    {
      title: 'Illustraci la tua idea',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do e',
      img: BluniStudentDesk2,
      pattern: Pattern1
    },
  ]

  const onButtonClicked = () => {
    navigate("/portfolio");
  }

  function renderDeskVersion() {
    return (
      <div id={sectionName} className="position-relative">
        <div className="portfolio-title no-selectable">Blunìfolio</div>
        <img className="portfolio-divider" src={Divider} alt='' />
        {
          portfolioList.map((el, idx) => {
            return (
              <div key={idx} className={`portfolio-row d-flex ${idx % 2 !== 0 ? 'flex-row-reverse' : ''}`}>
                <div className="image-row col p-0 d-flex align-items-center justify-content-center">
                  <img className="pattern" src={el.pattern} alt='' />
                  <img src={el.img} alt='' />
                </div>
                <div className="detail-row col p-0 d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-column">
                    <Title className="title">{el.title}</Title>
                    <div className="description-t">Descrizione</div>
                    <Paragraph className="description mt-0">{el.description}</Paragraph>
                    <div className="mt-5">
                      {
                        el.detail?.map((item, idxItem) => {
                          return (
                            <div key={idxItem} className="d-flex align-items-center my-2">
                              <div className="circle">
                                <FontAwesomeIcon className="icon" icon={faCheck} />
                              </div>
                              <div className="item ml-2">
                                {item}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        <div className='d-flex flex-row justify-content-center align-items-center my-5'>
          <Button
            className="button d-flex align-items-center justify-content-between"
            buttonClicked={() => onButtonClicked()}>
            Visualizza portfolio completo
            <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-3" />
          </Button>
        </div>
      </div>
    )
  }

  function renderMobileVersion() {
    return (
      <section id={sectionName} className="p-0">
        <SectionTitle className="mobile-v pl-4">Blunìfolio</SectionTitle>
        {
          portfolioList.map((el, idx) => {
            return (
              <div key={idx} className={`portfolio-row mob d-flex flex-column`}>
                <div className="detail-row mob col py-5 d-flex align-items-center justify-content-center flex-fill">
                  <div className="d-flex flex-column">
                    <Title className="title">{el.title}</Title>
                    <div className="description-t">Descrizione</div>
                    <Paragraph className="description mt-0">{el.description}</Paragraph>
                    {
                      el.detail?.length > 0
                        ? <div className="mt-5">
                          {el.detail?.map((item, idxItem) => {
                            return (
                              <div key={idxItem} className="d-flex align-items-center my-1">
                                <div className="circle">
                                  <FontAwesomeIcon className="icon" icon={faCheck} />
                                </div>
                                <div className="item ml-2">
                                  {item}
                                </div>
                              </div>
                            )
                          })
                          }
                        </div> : <></>
                    }
                  </div>
                </div>
                <div className="image-row mob col py-5 d-flex align-items-center justify-content-center  flex-fill">
                  <img src={el.img} alt='' />
                </div>
              </div>
            )
          })
        }
        <div className='d-flex flex-row justify-content-center align-items-center my-5'>
          <Button
            className="button d-flex align-items-center justify-content-between"
            buttonClicked={() => onButtonClicked()}>
            Visualizza portfolio completo
            <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-3" />
          </Button>
        </div>
      </section>
    )
  }

  return (
    <>
      {
        gridValue === MOBILE ?
          renderMobileVersion()
          : renderDeskVersion()
      }
    </>
  )
}
